import { useEffect, useState, useRef } from "react";
import TextInput from "@/Components/TextInput";
import { router, usePage } from "@inertiajs/react";

const SearchFilter = ({ routeName, placeholder }) => {
    const { filters } = usePage().props;

    const [searchQuery, setSearchQuery] = useState({
        search: filters.search || "",
    });

    // Hook
    const usePrevious = (value) => {
        const ref = useRef();
        // Store current value in ref
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    };

    const prevValues = usePrevious(searchQuery);

    useEffect(() => {
        if (prevValues) {
            const url = route(routeName);

            router.get(url, searchQuery, {
                preserveState: true,
            });
        }
    }, [searchQuery]);

    const handleSearch = (e) => {
        const search = e.target.value;
        setSearchQuery((searchQuery) => ({ ...searchQuery, search }));
    };

    return (
        <>
            <TextInput
                type="text"
                className="px-3 w-25 mb-2"
                name="search"
                value={searchQuery.search}
                onChange={handleSearch}
                placeholder={placeholder}
            />
        </>
    );
};

export default SearchFilter;
