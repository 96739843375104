import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import { Col, Container, Row } from "react-bootstrap";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

import { Line } from "react-chartjs-2";

export default function ChatReport({
    total_price,
    total_refund,
    day,
    role_id,
    total_sum,
    total_refund_sum,
    gross_commission,
    net_commission,
    total_payouts,
    current_balance,
    total_charge_back,
    security_deposit,
}) {
    const labels = JSON.parse(day);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: false,
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: "Last week purchase: $" + total_sum,
                data: JSON.parse(total_price),
                borderColor: "#024f6e",
            },
            {
                label: "Last week refund: $" + total_refund_sum,
                data: JSON.parse(total_refund),
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
        ],
    };

    return (
        <>
            <Container fluid={true}>
                <Row>
                    <Col md={10} sm={12} lg={10}>
                        <Line options={options} data={data} height={100} />
                    </Col>

                    <Col
                        md={2}
                        sm={12}
                        lg={2}
                        className={`custom_list ${
                            role_id == 2 && "justify-content-start"
                        }`}
                    >
                        {role_id == 1 && (
                            <>
                                <div className="custom_item_list">
                                    <h6>Gross Commission</h6>
                                    <p>
                                        <strong>
                                            ${gross_commission?.toFixed(2)}
                                        </strong>
                                    </p>
                                </div>
                                <div className="custom_item_list">
                                    <h6>Net Commission</h6>
                                    <p>
                                        <strong>
                                            ${net_commission?.toFixed(2)}
                                        </strong>
                                    </p>
                                </div>
                            </>
                        )}

                        <div className="custom_item_list">
                            <h6> Total Payout</h6>
                            <p>
                                <strong>
                                    $
                                    {total_payouts
                                        ? total_payouts?.toFixed(2)
                                        : "0.00"}
                                </strong>
                            </p>
                        </div>
                        <div className="custom_item_list">
                            <h6> Security Balance</h6>
                            <p>
                                <strong>
                                    $
                                    {security_deposit
                                        ? security_deposit?.toFixed(2)
                                        : "0.00"}
                                </strong>
                            </p>
                        </div>
                        <div className="custom_item_list">
                            <h6>Total Charge Back</h6>
                            <p>
                                <strong>
                                    $
                                    {total_charge_back
                                        ? total_charge_back?.toFixed(2)
                                        : "0.00"}
                                </strong>
                            </p>
                        </div>
                        <div className="custom_item_list">
                            <h6>Current Balance</h6>
                            <p>
                                <strong>
                                    $
                                    {current_balance
                                        ? current_balance?.toFixed(2)
                                        : "0.00"}
                                </strong>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
