import { Form, Col } from "react-bootstrap";
import { InputError } from "@/Components/index";
import React from "react";

const FormGroup = React.forwardRef(
    (
        {
            name,
            type,
            label,
            value,
            onValueChange,
            placeholder,
            message,
            size,
            id,
            as,
            children,
            readOnly,
            required,
        },
        ref
    ) => {
        return (
            <Col md={size} className="rounded">
                <Form.Group>
                    <Form.Label htmlFor={id}>{label}</Form.Label> <br />
                    {children}
                    <Form.Control
                        style={{
                            background: "white",
                            width:
                                name === "digitOne" ||
                                name === "digitTwo" ||
                                name === "digitThree" ||
                                name === "digitFour" ||
                                name === "digitFive" ||
                                name === "digitSix"
                                    ? "50px"
                                    : "",
                            height:
                                name === "digitOne" ||
                                name === "digitTwo" ||
                                name === "digitThree" ||
                                name === "digitFour" ||
                                name === "digitFive" ||
                                name === "digitSix"
                                    ? "50px"
                                    : "",
                            textAlign:
                                name === "digitOne" ||
                                name === "digitTwo" ||
                                name === "digitThree" ||
                                name === "digitFour" ||
                                name === "digitFive" ||
                                name === "digitSix"
                                    ? "center"
                                    : "left",
                        }}
                        ref={ref}
                        readOnly={readOnly}
                        as={as}
                        id={id}
                        type={type}
                        name={name}
                        value={value}
                        onChange={onValueChange}
                        placeholder={placeholder}
                        required={required}
                    />
                    <br />
                    <InputError message={message} className="mt-2" />
                </Form.Group>
            </Col>
        );
    }
);

export default FormGroup;
